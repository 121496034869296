/**
 * Table with All the Entity info
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, CardContent, Container, createStyles, Theme } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { formatUtc } from '../lib/utils';

import { StoreState } from '../lib/redux';
import { Entity } from '../lib/types';
import { WithApiProps, withApi } from '../providers';
import TotalSiteViewTable from '../components/TotalSiteViewTable';
import { makeDisplayId } from '../lib/utils';

const styles = (theme: Theme) => createStyles({
  container: {
    padding: 0,
    height: '100%',
    // Force overflow handling into the children
    overflowY: 'scroll',
      '& > *': {
      // Put a space between the children
      marginBottom: theme.spacing(2),
      '&:last-child': {
        // But not at the very end
        marginBottom: 0
      }
    }
  },
});

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    robotEntities: state.entities,
    prefs: state.prefs,
    sites: state.sites,
    sitesMap: state.sitesMap,
    limitedSites: state.limitedSites,
    awsUsername: state.awsUser?.extractedUsername,
  }),
)

type State = {
  rows: Entity[];
};

type Props = WithStyles<typeof styles> &
  ConnectedProps<typeof connector> &
  WithApiProps &
  WithTranslationProps & {};

class TotalSiteView extends React.Component<Props> {
  state: State = {
    rows: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    this.setState({ rows: this.prepareRows(this.props.robotEntities as Entity[]) });
  }

  componentDidUpdate(prev: Props) {
    if (prev.robotEntities !== this.props.robotEntities || this.props.limitedSites !== prev.limitedSites) {
      this.setState({ rows: this.prepareRows(this.props.robotEntities as Entity[]) });
    }
  }

  render() {
    const { rows } = this.state;

    // If no data, display a meaningful message to the user.
    if (!rows.length) {
      return (
        <Container className={this.props.classes.container} maxWidth={false}>
          <Card>
            <CardContent>
              <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center' }}>
                No Data to Show
              </span>
            </CardContent>
          </Card>
        </Container>
      );
    }

    // Split rows into two categories based on entityType and isRobotDisabled status
    const nonRobotAlerts = rows.filter(row => row.entityType !== "ROBOT" && row.alerts?.length > 0);
    const robotEntities = rows.filter(row => row.entityType === "ROBOT" && !row.robot.isRobotDisabled);

    return (
      <Container className={this.props.classes.container} maxWidth={false}>
        {robotEntities.length > 0 && (
          <TotalSiteViewTable key="robots" rows={robotEntities} robotEntity={true} />
        )}
        {nonRobotAlerts.length > 0 && (
          <TotalSiteViewTable key="alerts" rows={nonRobotAlerts} robotEntity={false} />
        )}
      </Container>
    );
  }

  /**
   * Enriches the basic Entity models and prepares rows for different tables such as robot entities vs non robot alerts
   * fetched from the backend with additional properties for display, and filters based on site selection
   *
   * @param {Entity[]} entities - The list of entities passed in from props
   * @returns {Entity[]} - A list of entities visible according to the current site selection filter, with additional properties filled in
   */
  prepareRows = (entities: Entity[]): Entity[] => {
    const { sites, limitedSites, awsUsername } = this.props;
    const siteMap = new Map(sites.map(site => [site.siteId, site]));
    const limitedSiteSet = limitedSites?.length ? new Set(limitedSites) : null;

    return entities
      .map((entity) => {
        const siteExists = siteMap.has(entity.siteId);
        if (!siteExists) return null;

        const loggedInUser = entity.claim?.claimedUser === awsUsername;
        const baseData = {
          ...entity,
          siteName: siteMap.get(entity.siteId)?.displayName || "",
          siteUrl: siteMap.get(entity.siteId)?.url || "",
          claimedBy: entity.claim?.claimedUser || "Unclaimed",
          isClaimedByUser: loggedInUser ? "Yes" : "No",
          claimedStatus: entity.claim?.claimedUser ? 'Claimed' : 'Unclaimed',
          claimedAt: formatUtc(entity.claim?.claimedTime),
        };

        // Handle non-robot entities with alerts
        if (entity.entityType !== "ROBOT") {
          if (limitedSiteSet && !limitedSiteSet.has(entity.siteId)) return null;

          if (entity.alerts?.length) {
            // Ensure alerts have messages
            entity.alerts.forEach(a => a.message = this.t(a.messageKey, a.messageArgumentValues));
            return {
              ...baseData,
              alerts: entity.alerts,
              messages: entity.alerts?.map(a => a.message ?? '') ?? [],
            } as Entity;
          }

          return baseData as Entity;
        }

        // Handle robot entities
        if (entity.entityType === "ROBOT" && entity.robot && !entity.robot.isRobotDisabled) {
          if (limitedSiteSet && !limitedSiteSet.has(entity.robot.siteId)) return null;
          return {
            ...baseData,
            numAlert: entity.alerts?.length ?? 0,
          } as Entity;
        }
        return null;
      })
      .filter((entity): entity is Entity => entity !== null);
  };

  /**
   * Translate message.
   *
   * @param key message key
   * @param args message arguments
   * @returns translated message, or key if not found
   */
  t = (key: string, args: any[]): string  => {
    const { i18n } = this.props;
    return  i18n ? i18n.t(key, args) : key;
  }
}

export default connector(
  withTranslation()(
    withApi(withStyles(styles)(TotalSiteView))
  )
);