/**
 * Array list of menu items for the sidebar navigation.
 */

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const MENU = [
  {
    label: "Alerts",
    appTitle: "Pivotal Alerts Central",
    icon: <ExpandMoreIcon />,
    link: "/",
  },

  {
    label: "Robot Profile",
    appTitle: "Robot Profile",
    icon: <ExpandMoreIcon />,
    link: "/robot-profile",
  },

  {
    label: "Site View",
    appTitle: "Total Site View",
    icon: <ExpandMoreIcon />,
    link: "/site-profile",
  },
];
