/**
 * Expandable tables of sites with robot data and non robot alerts
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Container, Typography, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableCurrentData,
  MUIDataTableOptions
} from 'mui-datatables';
import cx from 'classnames';
import moment from 'moment';

import { Entity, Alert } from '../lib/types';
import { formatTime, formatTimeToday, formatTimeLocale, renderSiteLink, applyFilters, filterUpdater } from '../lib/utils';
import { StoreState } from '../lib/redux';
import { commonStyles } from '../lib/styles';

import { FiberManualRecord } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Cell properties to make the column
const MIN_CELL = () => ({ style: { width: '100px', minWidth: '50px' }});
const WIDE_CELL = () => ({ style: { width: '150px', minWidth: '50px' }});

const styles = commonStyles;

/** Table row */
export type Row = Entity;

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    prefs: state.prefs,
  }),
)

type Props = {
  /** The rows to display in the table */
  rows: Row[];
  /** Are these rows Robot Entities */
  robotEntity: boolean;
} & WithStyles<typeof styles> & ConnectedProps<typeof connector>;

type State = {
  /** Is the table opened? */
  open: boolean;
  /** Saved column filters */
  filters: Record<string, string[]>;
  entities: Entity[],
  hideOfflineRobotOption: boolean,
  /** IDs of expanded entities */
  expanded: string[];
}

class TotalSiteViewTable extends React.Component<Props, State> {
  state: State = {
    open: false,
    entities: [],
    hideOfflineRobotOption: false,
    filters: {},
    expanded: [],
  }

  constructor(props: Props) {
    super(props);
    // Load the saved column filters
    this.state.filters = this.loadFilters();
    this.state.hideOfflineRobotOption = false;
    this.state.entities = this.props.rows;
  }

  componentDidUpdate(prev: Props) {
    if (prev.rows !== this.props.rows) {
      this.setState({
        entities: this.props.rows,
        hideOfflineRobotOption: false,
      });
    }
  }

  render() {
    const { rows, robotEntity = false } = this.props;
    const { open } = this.state;

    const HeaderElements = () => (
      <>
        {robotEntity && this.state.open ? (
          <Button onClick={this.filterRobotsByStatus}>
            {!this.state.hideOfflineRobotOption ? 'Hide Offline Robots' : 'Show Offline Robots'}
          </Button>
        ) : null}
      </>
    );

    // Prepare the table
    const columns  = this.prepareColumns();
    const expanded = this.findRowsExpanded(rows);

    // Apply the saved column filters
    applyFilters(this.state.filters, columns);

    // Override table components to hide all but the toolbar when not open
    const components = open ? {} : {
      TableBody: () => null,
      TableHead: () => null,
      TableFilterList: () => null,
    };

    const options: MUIDataTableOptions = {
      // Remove the box shadow
      elevation: 0,
      pagination: true,

      customToolbar: () => <HeaderElements />,
      // No reason to select the rows
      selectableRows: 'none',
      // Don't stack the cells for narrow windows
      responsive: 'standard',
      // Don't allow selecting columns
      viewColumns: false,

      // Manage the row expansion
      expandableRows: !robotEntity,
      isRowExpandable: robotEntity ? undefined : (dataIndex: number) => {
        let row: Row = rows[dataIndex];
        return row?.alerts?.length > 1;
      },
      expandableRowsOnClick: !robotEntity,
      rowsExpanded: robotEntity ? undefined : this.state.expanded.map(id => rows.findIndex(row => row.entityId === id)),
      onRowExpansionChange: robotEntity ? undefined : this.expandedEntitiesUpdater(rows),
      renderExpandableRow: robotEntity ? undefined : this.expandedRowRenderer(rows),

      // Manage the CSV download
      downloadOptions: {
        filterOptions: {
          // Only download the rows that match the filter
          useDisplayedRowsOnly: true,
        }
      },
      // Manage the filtering
      onFilterChange: filterUpdater(columns, this.setState.bind(this), this.makeStorageKey),
      }

    let title: React.ReactNode;
    let tableData: Entity[] = [];

    if (robotEntity) {
      // **Robot Table**
      const onlineRobotsCount = this.state.entities.filter((r) => r.robot.isRobotOnline).length;
      const totalRobotsCount = this.state.entities.filter((r) => !r.robot.isRobotDisabled).length;

      title = (
        <Typography classes={{ root: this.props.classes.title }} variant="h6" onClick={this.toggleTable}>
          {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          {totalRobotsCount > 0
          ? `Overall Site Robot View (${onlineRobotsCount}/${totalRobotsCount})`
          : '(No Robot Data)'}
        </Typography>
      );
      tableData = this.state.entities;
    } else {
      // **Non-Robot Alerts Table**
      title = (
        <Typography classes={{ root: this.props.classes.title }} variant="h6" onClick={this.toggleTable}>
          {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          {'Overall Site Non Robot Alerts View'} ({this.state.entities.length})
        </Typography>
      );
      tableData = this.state.entities;
    }

    return (
      <Container className={cx(this.props.classes.table, { open })} maxWidth={false}>
        <MUIDataTable
          title={title}
          options={options}
          columns={columns}
          data={tableData}
          components={components}
        />
      </Container>
    );
  }

  /**
   * Toggle the table visibility
   */
  toggleTable = () => {
    this.setState((state) => ({
      open: !state.open
    }));
  }

  /**
   * Filter the rows based on the robot online or offline flag
   *
   * @returns rows matching the isRobotOnline flag
   */
  filterRobotsByStatus = () => {
    const { rows } = this.props;
    const hideOfflineRobots = !this.state.hideOfflineRobotOption;
    const filteredRobots = rows.filter(r => hideOfflineRobots ? r.robot.isRobotOnline : true);
    this.setState({entities: filteredRobots, hideOfflineRobotOption: hideOfflineRobots})
  }

  /**
   * Prepare the table column definitions
   *
   * @param rows all the data rows
   * @returns table column definitions
   */
  prepareColumns = (): MUIDataTableColumn[] => {
    // Get the start of today for timestamp formatting
    const today = this.getToday();
    const { robotEntity } = this.props;

    const columns: MUIDataTableColumn[] = [
      { // Raw entity to help with CSV download - see downloadCsv()
        name: 'robots',
        options: {
          display: 'excluded',
          download: false,
          filter: false,
          searchable: false
        }
      },
      {
        label: 'online',
        name: 'isRobotOnline',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: false,
          filter: true,
          searchable: true
        }
      },
      {
        label: robotEntity ? 'Serial Number' : 'Entity',
        name: 'entityId',
          options: {
            setCellProps: MIN_CELL,
            setCellHeaderProps: MIN_CELL,
            display: true,
            filter: true,
            filterType: 'multiselect',
            searchable: true,
            customBodyRenderLite: robotEntity ? this.renderLite(this.state.entities, this.renderRobotSerialWithStatus) : undefined,
          },
        },
      {
        label: 'Name',
        name: 'entityName',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          display: true,
          filter: true,
          filterType: 'multiselect',
          searchable: true
        }
      },
      {
        label: 'Site',
        name: 'siteName',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          filter: false,
          searchable: false,
          filterType: 'multiselect',
          customBodyRenderLite: this.renderLite(this.state.entities, r => renderSiteLink(r)),
        }
      },
      {
        label: 'Operational Status',
        name: 'robotOperationalState',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          filterType: 'multiselect',
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.entities, r => r.robot.robotOperationalState ? r.robot.robotOperationalState : "-")
        }
      },
      {
        label: 'Availability',
        name: 'isRobotAvailable',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.entities, this.renderRobotNameWithAvailabilityStatus)
        }
      },
      {
        label: 'No.of alerts',
        name: 'numAlert',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true
        }
      },
      {
        label: 'Last Online',
        name: 'robotLastOnline',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: false,
          searchable: false,
          customBodyRenderLite: this.renderLite(this.state.entities, r => r.robot.isRobotOnline ? "Online" : this.renderTimestamp(r.robot.robotLastOnline, today))
        }
      },
      {
        label: 'Message',
        name: 'messages',
        options: {
          customBodyRenderLite: this.renderLite(this.state.entities, r => r.alerts[0]?.message || ''),
          filterOptions: {
            // Find the unique messages for the filter dropdown
            names: this.findUniqueMessages(this.state.entities),
          }
        }
      },
      {
        label: 'Suppressed',
        name: 'suppressed',
        options: {
          setCellProps: MIN_CELL,
          display: true,
          filter: false,
          filterType: 'multiselect',
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.entities, r => (r.alerts?.length && r.alerts.every(alert => alert.suppressed)) ? 'True' : 'False'),
        }
      },
      {
        label: 'Claimed By',
        name: 'claimedBy',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          display: true,
          filter: true,
          filterType: 'multiselect',
          searchable: true,
         customBodyRenderLite: this.renderLite(this.state.entities, r =>  r.claim?.claimedUser ?? "N/A"),
        }
      },
      {
        label: 'Created',
        name: 'createdTime',
        options: {
          setCellProps: MIN_CELL,
          customBodyRenderLite: this.renderLite(this.state.entities, r => this.renderTimestamp(r.alerts[0]?.createdTime, today)),
          filter: false,
          searchable: false,
        }
      },
      {
        label: 'Claimed At',
        name: 'claimedAt',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          searchable: false,
          display: true,
          filter: false,
          customBodyRenderLite: this.renderLite(this.state.entities, r => this.renderTimestamp(r.claimedAt, today)),
        }
      },
      {
        label: 'Claimed Status',
        name: 'claimedStatus',
        options: {
          display: false,
          filter: true,
          searchable: false,
          filterType: 'dropdown',
          filterOptions: {
            names: ['Claimed', 'Unclaimed'],
          },
        },
      },
      {
        label: 'My Current Claims',
        name: 'isClaimedByUser',
        options: {
          display: false,
          filter: true,
          filterType: 'checkbox',
          searchable: false,
          filterOptions: {
            names: ['Yes'],
          },
        },
      },
    ];
    // Columns required for each view
      const robotViewColumns = [
        'entityId',
        'entityName',
        'siteName',
        'robotOperationalState',
        'isRobotAvailable',
        'numAlert',
        'robotLastOnline',
        'claimedBy',
        'claimedAt',
        'isClaimedByUser',
        'claimedStatus',
      ];
      const nonRobotViewColumns = [
        'entityId',
        'messages',
        'suppressed',
        'siteName',
        'claimedBy',
        'createdTime',
        'claimedAt',
        'claimedStatus',
        'isClaimedByUser',
      ];

      // Filter columns based on view type
      return columns.filter(col =>
        robotEntity ? robotViewColumns.includes(col.name) : nonRobotViewColumns.includes(col.name)
      );
    }

  /**
   * Wrapper that finds the original data row and renders a field.
   *
   * @param rows all the rows in the table
   * @param callback function that renders the field
   * @returns wrapper for customBodyRenderLite
   */
  renderLite = (rows: Entity[], callback: (row: Entity) => any) => {
    return (dataIndex: number, _rowIndex: number) => {
      const row = rows[dataIndex] as Entity;
      if (!row) {
        return null;
      }

      return callback.call(null, row);
    };
  }

  /**
   * Find all the unique alert messages.
   *
   * @param rows Rows in the table
   * @returns sorted array of messages
   */
  findUniqueMessages = (rows: Row[]): string[] => {
    const msgs = new Set<string>();
    rows.forEach(r => r.alerts?.forEach(a => msgs.add(a.message ?? '')))
    return Array.from(msgs).sort();
  }

  /**
   * Build a wrapper to render the expanded row.
   *
   * @param rows all the data rows
   * @returns wrapper for renderExpandableRow
   */
  expandedRowRenderer = (rows: Row[]) => {
    const { classes } = this.props;

    // Get the start of today for timestamp formatting
    const today = this.getToday();

    return (_rowData: string[], rowMeta: MUIDataTableCurrentData) => {
      const row = rows[rowMeta.dataIndex];
      if (!row) {
        // Could not find the row
        return null;
      }

      // Give each alert (except the first which is already visible) its own row in the table
      const entity: Entity = row;
      return entity.alerts.slice(1).map((a: Alert) => (
       <tr key={a.id} className={`MuiTableRow-root MuiTableRow-hover ${a.suppressed ? 'suppressed' : ''}`}>
         <td className={cx('MuiTableCell-root MuiTableCell-body', classes.noprint)}></td>
         <td className="MuiTableCell-root MuiTableCell-body" colSpan={2}></td>
         <td className="MuiTableCell-root MuiTableCell-body">{a.message}</td>
         <td className="MuiTableCell-root MuiTableCell-body">{a.suppressed ? 'true' : 'false'}</td>
         <td className="MuiTableCell-root MuiTableCell-body" colSpan={1}></td>
         <td className="MuiTableCell-root MuiTableCell-body">{this.renderTimestamp(a.createdTime, today)}</td>
       </tr>
      ));
    }
  }

  /**
   * Build a wrapper to update the list of expanded entities when the row
   * expansion changes.
   * @param rows all the data rows
   * @returns wrapper for onRowExpansionChange
   */
  expandedEntitiesUpdater = (rows: Row[]) => {
    return (_current: any, all: MUIDataTableCurrentData[], _expanded: any) => {
      this.setState(
        (prevState) => {
          const expandedIds = all.map(row => rows[row.dataIndex].entityId);
          return { expanded: expandedIds };
        }
      );
    };
  }

  /**
   * Determine which rows in the table should be expanded based on which entities have been expanded.
   *
   * @param rows all the data rows
   * @returns list of row indexes that whould be expanded
   */
  findRowsExpanded = (rows: Row[]): number[] => {
    const { expanded } = this.state;
    return rows.map((r, idx) => expanded.includes(r.entityId) ? idx : -1).filter(idx => idx !== -1);
  }

  /**
   * Find the start of the current day
   *
   * @returns the start of the day, as a Moment
   */
  getToday = () => moment().startOf('date');

  /**
   * Render a timestamp with a tooltip based on the user preferences
   */
  renderTimestamp = (time: any, today: moment.Moment) => {
    const { prefs } = this.props;

    // Format the time based on the user preferences
    const text = prefs.todayTime
      ? formatTimeToday(time, today)
      : formatTime(time);
    return (
      <Tooltip title={formatTimeLocale(time)}>
        <span>{text}</span>
      </Tooltip>
    );
  }

  /**
   * Render the robot with online/offline status for the row
   *
   * @param row table row
   * @returns Rendered button
   */
  renderRobotSerialWithStatus = (row: Entity) => {
    // Note: the extra <span> is to work around tooltips not appearing on disabled elements.
    let tooltip = row.robot.isRobotOnline ? "Online" : "Offline";
    return (
      <Tooltip title={tooltip}>
        <span><FiberManualRecord className={row.robot.isRobotOnline ? 'online' : 'offline'} /> {row.entityId}</span>
      </Tooltip>
    );
  }

  /**
   * Render the robot with availability status for the row
   *
   * @param row table row
   * @returns Rendered icon
   */
  renderRobotNameWithAvailabilityStatus = (row: Entity) => {
    let tooltip = row.robot.isRobotAvailable ? "Available" : "Unavailable";
    const icon = row.robot.isRobotAvailable ? <CheckCircleIcon/> : <CancelIcon/>;
    return (
      <Tooltip title={tooltip}>
        <span>{icon}</span>
      </Tooltip>
    );
  }

  /**
   * Build the local storage key for a type of data
   *
   * @param data_type type of data (filters, etc)
   * @returns key into local storage
   */
  makeStorageKey = (data_type: string): string => {
      const tableType = this.props.robotEntity ? "robot_table" : "alert_table";
      return `total_site_view.${tableType}.${data_type}`;
  }

  /**
   * Load the filter list values from local storage
   *
   * @returns filter lists
   */
  loadFilters = (): Record<string, string[]> => {
    // Fetch the saved JSON value from local storage
    const saved = localStorage.getItem(this.makeStorageKey('filters'));
    return saved ? JSON.parse(saved) : {};
  }
}

export default connector(
  withStyles(styles)(TotalSiteViewTable)
);